@use 'variables' as *;
@use "sass:math";

// Helper to convert straight number to percentage
@function convertify($number) {
  @if type-of($number) == 'number' and unitless($number) {
    @return percentage(math.div($number, $grid-column-count));
  }

  @return $number;
}

// Rows
@mixin grid-row(
  $direction: ltr,
  $align: stretch,
  $justify: flex-start,
  $grid-gutter: $gutter,
  $wrap: wrap
) {
  display: flex;
  flex-wrap: $wrap;

  align-items: $align;
  justify-content: $justify;

  margin-left: -(math.div($grid-gutter, 2));
  margin-right: -(math.div($grid-gutter, 2));

  @if $direction == ltr {
    flex-direction: row;
  } @else {
    flex-direction: row-reverse;
  }
}

// Columns
@mixin grid-col($width: 100%, $align: stretch, $grid-gutter: $gutter) {
  flex: none;
  align-self: $align;

  padding-left: math.div($grid-gutter, 2);
  padding-right: math.div($grid-gutter, 2);

  width: convertify($width);
}

// Offsets
@mixin grid-offset-left($offset: 1) {
  margin-left: convertify($offset);
}

@mixin grid-offset-right($offset: 1) {
  margin-right: convertify($offset);
}
