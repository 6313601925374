@import '~styles/config';

.modal {
  display: flex;
  align-items: center;

  padding: responsive(1rem, 2rem);

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  overflow: auto;

  background: rgba(#000, 0.5);
  z-index: 2000;

  &.blur {
    backdrop-filter: blur(1rem);
  }

  &__box {
    position: relative;
    margin: auto;

    max-width: min(88rem, 100%);
    backface-visibility: hidden;

    outline: none;
    border: none;

    background: $color-background;
    box-shadow: 0 0 1rem 0 rgba(#000, 0.02), 0 6rem 6rem 0 rgba(#000, 0.1);
  }

  &__body {
    padding: 3rem 5rem 3rem 3rem;

    @media (min-width: $min-720) {
      padding: 8rem;
    }
  }

  &__footer {
    position: sticky;
    bottom: 0;
    bottom: responsive(-1rem, -2rem);
    left: 0;
    right: 0;
    min-height: 5rem;
    background: rgba(#fff, 0.75);
    backdrop-filter: blur(0.3rem);
    border-top: 1px solid $color-border;
  }

  &__close {
    @include reset-button;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 4.4rem;
    height: 4.4rem;
    cursor: pointer;

    @media (min-width: $min-720) {
      top: 2rem;
      right: 2rem;
    }
  }

  &__cross {
    position: absolute;

    margin-left: -1px;

    width: 2px;
    height: 20px;

    background: #000;

    transform: rotate(45deg);

    &:first-child {
      transform: rotate(-45deg);
    }
  }
}

.verification,
.warning {
  &__tagline {
    @include taglineWithLine;
  }

  &__title {
    @include h2(2.8rem, 4.2rem);
    margin-bottom: 0.6em;
    // overflow-wrap: break-word;
    hyphens: auto;
  }

  &__text {
    @include render-copy;
  }
}
