@use 'styles/config' as *;
@use "sass:math";

.button {
  $self: &;
  @include reset-button();
  @include reset-anchor();
  max-width: 92vw;

  display: inline-block;
  position: relative;

  font-size: responsive(1.3rem, 1.6rem);
  font-weight: 600;
  color: $color-font;
  text-transform: uppercase;
  letter-spacing: math.div(0.89, 16) * 1em;

  cursor: pointer;

  background: brand();

  box-shadow: 0 4.8rem 3.2rem 0 rgba(#000, 0.1);

  transition-property: background-color, color;
  transition-duration: 200ms;

  @media (min-width: $min-720) {
    // white-space: nowrap;
  }

  @media (min-width: $min-1080) {
    box-shadow: 0 6rem 4rem 0 rgba(#000, 0.1);
  }

  &.small {
    letter-spacing: normal;
    box-shadow: 0 3rem 3.5rem 0 rgba(#000, 0.1);
  }

  svg {
    display: block;

    transition-property: fill;
    transition-duration: $transition-enter;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 2.75em;
    padding-right: 0.8em;
    min-height: 8rem;

    transition-property: padding;
    transition-duration: $transition-enter;

    @media (min-width: $min-720) {
      padding-left: 3.75em;
      padding-right: 1.875em;
    }

    @media (min-width: $min-1080) {
      min-height: 10rem;
    }

    #{ $self }.small & {
      justify-content: center;
      padding-left: 1em;
      padding-right: 1em;
      height: 5rem;
      min-width: 12rem;

      @media (min-width: $min-720) {
        padding-left: 2em;
        padding-right: 2em;
      }

      @media (min-width: $min-1080) {
        min-width: 18rem;
        height: 6rem;
      }
    }
  }

  &__icon {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    width: 5em;

    svg {
      width: 1.1rem;
      height: 1.125em;
    }
  }

  &.brand {
    color: $color-dark;
    background: brand();

    svg {
      fill: $color-dark;
    }

    &:is(:hover, :focus-visible) {
      color: #fff;
      background: $color-dark;

      svg {
        fill: #fff;
      }

      :global(body.dark) & {
        color: $color-dark;
        background: #fff;

        svg {
          fill: $color-dark;
        }
      }
    }

    :global(.adalvo) & {
      color: brandFont($defaultColor: $color-dark);

      &:hover {
        background: brandDark();
      }

      svg {
        fill: brandFont($defaultColor: $color-dark);
      }
    }

    :global(.almatica) & {
      color: brandFont();

      &:hover {
        background: brandDark();
      }

      svg {
        fill: brandFont();
      }
    }
  }

  &.white {
    color: brand();
    background: #fff;

    svg {
      fill: brand();
    }

    &:is(:hover, :focus-visible) {
      color: #fff;
      background: $color-dark;

      svg {
        fill: #fff;
      }

      :global(.almatica) & {
        color: brandFont();
        background: brandDark();
      }

      :global(body.dark) & {
        color: $color-dark;
        background: brand();

        svg {
          fill: $color-dark;
        }
      }
    }
  }

  &.dark {
    color: #fff;
    background: $color-dark;

    svg {
      fill: #fff;
    }

    &:is(:hover, :focus-visible) {
      color: $color-dark;
      background: brand();

      svg {
        fill: $color-dark;
      }

      :global(body.brand) & {
        color: $color-dark;
        background: #fff;

        svg {
          fill: $color-dark;
        }
      }
    }

    :global(.almatica) & {
      color: brandFont();
      background: brandDark();

      &:hover {
        background: $color-dark;
      }

      svg {
        fill: brandFont();
      }
    }
  }

  &.hoverBrand {
    &,
    :global(body.brand) &,
    :global(body.white) &,
    :global(body.dark) & {
      &:is(:hover, :focus-visible) {
        color: $color-dark;
        background: brand();

        svg {
          fill: $color-dark;
        }
      }
    }
  }

  &.hoverWhite {
    &,
    :global(body.brand) &,
    :global(body.white) &,
    :global(body.dark) & {
      &:is(:hover, :focus-visible) {
        color: $color-dark;
        background: #fff;

        svg {
          fill: $color-dark;
        }
      }
    }
  }

  &.hoverDark {
    &,
    :global(body.brand) &,
    :global(body.white) &,
    :global(body.dark) & {
      &:is(:hover, :focus-visible) {
        color: #fff;
        background: $color-dark;

        svg {
          fill: #fff;
        }
      }
    }
  }

  &__text {
    display: block;
    padding-right: 0.25em;

    @media (min-width: $min-720) {
      padding-right: 1.25em;
    }

    #{ $self }.small & {
      padding-right: 0;
    }
  }
}
