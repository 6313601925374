@use 'styles/config' as *;

.preview {
  position: fixed;
  bottom: 1.3em;
  right: 1.3em;
  z-index: 9999;

  display: block;
  padding: 0.3rem 1rem;
  opacity: 0.9;

  background: #000;
  color: #fff;
  border-radius: 0.3rem;
  box-shadow: 0 0 0 0.1rem rgba(#fff, 0.1);

  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 1;
  }
}
