@use 'styles/config' as *;

.grid {
  z-index: 9001;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  overflow: hidden;
  pointer-events: none;

  transform-origin: 50% 0%;

  @media (min-width: $min-960) {
    right: $navigation-button-width;
  }

  &__container {
    @include container();

    opacity: 0;
    height: 100%;

    transition: opacity 0.2s;
  }

  &.verticalIsVisible &__container {
    opacity: 1;
  }

  &__row {
    @include grid-row();

    height: 100%;
  }

  &__column {
    @include grid-col(calc(100% / var(--grid-column-count, #{$grid-column-count})));

    position: relative;
    height: 100%;
  }

  &__visualize {
    position: relative;
    width: 100%;
    height: 100%;

    &::before,
    &::after {
      content: '';
      display: block;

      width: 100%;
      height: 100%;

      background: rgba(255, 255, 255, 0.1);
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;

      background: rgba(0, 0, 0, 0.05);
    }
  }
}
