@use 'styles/config' as *;

.button {
  @include reset-button;
  @include reset-anchor;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  padding: 2rem 0 0;
  width: 3.6rem;

  background: transparent;

  @media (min-width: $min-720) {
    padding: 4rem 0 0;
    width: 7.7rem;
  }

  &.hidden {
    display: none;
  }

  &.down {
    transform: rotate(180deg);
  }

  &__icon {
    display: block;
    width: 1rem;
    height: 1.7rem;
    transition: transform 200ms;

    .black & svg path {
      stroke: $color-font;
    }

    @media (min-width: $min-720) {
      width: 1.6rem;
      height: 2.5rem;
    }
  }

  &:hover &__icon {
    transform: translate3d(0, -0.5rem, 0);

    @media (min-width: $min-720) {
      transform: translate3d(0, -1rem, 0);
    }
  }

  &::before {
    content: '';
    position: absolute;
    opacity: 0.2;
    height: 0.2rem;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    transition: opacity 200ms;
  }

  &.black::before {
    opacity: 1;
    background-color: $color-font;
  }

  &:hover::before {
    opacity: 1;
  }
}
