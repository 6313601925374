@use 'styles/config' as *;

.pageLayout {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  min-height: 100vh;

  justify-content: flex-end;
  align-items: stretch;

  background: #eee;

  &__padding {
    flex: 1 1 auto;
    display: grid;
    align-items: stretch;
    padding-top: $layout-padding-top-mobile;
    padding-bottom: $layout-padding-bottom-mobile;

    @media (min-width: $min-720) {
      padding-top: $layout-padding-top;
      padding-bottom: $layout-padding-bottom;
    }
  }

  &.sidenav {
    --navigation-button-width: 6.3rem;
  }
}
