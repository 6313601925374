@use 'styles/config' as *;

.search {
  display: flex;
  width: 100rem;
  max-width: 100%;

  color: inherit;

  background-color: inherit;

  &.white {
    margin-top: 5rem;
    margin-bottom: -1rem;
  }

  form {
    display: flex;
    width: 100rem;
    max-width: 100%;
  }

  &__input {
    @include reset-input;
    flex: 1 1 auto;

    font-size: 2.8rem;
    color: inherit;
    background-color: inherit;

    &::placeholder {
      color: rgba(#fff, 0.3);
    }

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }
  }

  &__button {
    @include reset-button;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 4.4rem;
    height: 4.4rem;
    margin-right: 0.5rem;
    margin-left: -1rem;

    .brand & {
      color: brand();
    }

    .white & {
      color: #fff;
    }

    svg {
      width: 1.8rem;
      height: 1.8rem;
    }
  }
}
