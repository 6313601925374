@use 'styles/config' as *;

.button {
  @include reset-button;

  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  z-index: 1009;

  cursor: pointer;

  transition-property: background-color, color;
  transition-duration: 200ms;

  &.isOpen {
    color: #fff;
  }

  &.isDisable {
    pointer-events: none;
  }

  @media (max-width: $max-960) {
    position: fixed;
    top: 3rem;
    right: 4rem;

    height: 4.4rem;
    width: 4.4rem;

    transform: rotate(90deg);
  }

  @media (max-width: $max-720) {
    top: 0.4rem;
    right: 1.1rem;
  }

  @media (min-width: $min-960) {
    position: fixed 0;
    width: $navigation-button-width;
    left: auto;
    border-left: 0.1rem solid rgba(#000, 0.1);

    .isOpen &,
    :global(body.dark) &,
    :global(body.image) & {
      border-left-color: rgba(#fff, 0.1);
    }

    &.hideDesktop {
      display: none;
    }
  }

  &:hover,
  &:focus {
    @media (min-width: $min-960) {
      color: $color-font;
      @include brandColor;
    }
  }

  &__icons {
    position: relative;
    width: 20px;
    height: 20px;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 0 auto;

    width: 10px;
    height: 20px;

    overflow: hidden;
  }

  &__line {
    display: block;
    width: 2px;
    height: 20px;
    background: $color-font;

    transition-property: background-color;
    transition-duration: 200ms;

    .isOpen &,
    :global(body.dark) &,
    :global(body.image) & {
      background: #fff;
    }
  }

  &__close {
    position: absolute 0;

    width: 20px;
    height: 20px;

    opacity: 0;
    overflow: hidden;
  }

  &__cross {
    position: absolute;
    top: 0;
    left: 50%;

    margin-left: -1px;

    width: 2px;
    height: 20px;

    background: #000;

    transform: rotate(45deg);

    &:first-child {
      transform: rotate(-45deg);
    }

    .isOpen &,
    :global(body.dark) &,
    :global(body.image) & {
      background: #fff;
    }
  }

  &:hover &__line {
    @media (max-width: $max-960) {
      background: brand();

      :global(body.brand) {
        background: $color-dark;
      }
    }

    @media (min-width: $min-960) {
      background-color: $color-font;
    }
  }
}
