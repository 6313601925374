@use 'styles/config' as *;

.buttons {
  &__segment {
    @include segment();

    .article & {
      @include article-segment();
    }
  }

  &__container {
    @include container();

    .article & {
      padding: 0;
    }
  }

  &__titleWrap > *,
  &__title {
    @include h2();
    margin-bottom: 2em;
  }

  &__row {
    @media (min-width: $min-720) {
      @include grid-row();
    }
  }

  &__col {
    @media (min-width: $min-720) {
      @include grid-col(10);
    }

    @media (min-width: $min-1080) {
      @include grid-offset-left(1);
    }

    .article & {
      @media (min-width: $min-720) {
        @include grid-offset-left(1);
      }
      @media (min-width: $min-960) {
        @include grid-col(10);
        @include grid-offset-left(2);
      }
    }
  }

  &__list {
    column-gap: 3rem;
    row-gap: 3rem;

    .single-column & {
      display: grid;
      row-gap: responsive(3rem, 6rem);
      width: 100%;
      justify-content: flex-start;
      justify-items: flex-start;
    }

    .two-column &,
    .three-column &,
    .four-column & {
      display: grid;
      column-gap: responsive(3rem, 6rem);
      row-gap: responsive(3rem, 6rem);
      width: 100%;
      // justify-items: flex-start;
      align-items: flex-start;
      grid-template-columns: repeat(
        auto-fill,
        minmax(max(calc(50% - #{responsive(3rem, 6rem)}), 30rem), 1fr)
      );
    }

    .three-column & {
      grid-template-columns: repeat(
        auto-fill,
        minmax(max(calc(33.333333% - #{responsive(3rem, 6rem)}), 30rem), 1fr)
      );
    }

    .four-column & {
      grid-template-columns: repeat(
        auto-fill,
        minmax(max(calc(25% - #{responsive(3rem, 6rem)}), 22rem), 1fr)
      );
    }

    .left & {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    .center & {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    .right & {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
    }

    .space-between & {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
}
