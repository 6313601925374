@use 'styles/config' as *;
@use "sass:math";

.navigation {
  // display: none;
  overflow: hidden;

  position: fixed 0;
  z-index: $z-navigation;
  opacity: 0;

  color: #fff;

  pointer-events: none;

  &:focus-within {
    opacity: 1 !important;
    visibility: visible !important;
  }

  &__contentLayer {
    position: absolute 0;
    z-index: 3;
  }

  &__backgroundLayer {
    position: absolute 0;
    z-index: 2;
    background: #111;
  }

  &__brandLayer {
    position: absolute 0;
    z-index: 1;

    @include brandColor;
  }

  &.isOpen {
    // display: block;
    pointer-events: all;
  }

  &__padder {
    position: absolute 0;
    width: 100vw;
    overflow: hidden;
  }

  &__wrap {
    position: absolute 0;
    display: flex;
    flex-direction: column;
    margin-right: -30px;
    padding-right: 30px;
    overflow-x: auto;
    overflow-y: scroll;

    justify-content: space-between;
  }

  &__main {
    flex: 0 1 auto;
    // margin-right: 30px;
    padding-top: 6.6rem;

    @media (min-width: $min-720) {
      padding-top: 10rem + $gutter;
    }

    @media (min-width: $min-960) {
      margin-right: $navigation-button-width;
    }
  }

  &__container {
    @include container;
  }

  &__row {
    @include grid-row;
  }

  &__content {
    @include grid-col;
    padding-top: 4vh;
    padding-bottom: 4vh;

    @media (min-width: $min-840) {
      @include grid-col(6);
    }

    @media (min-width: $min-1080) {
      @include grid-col(7);
    }

    @media (min-width: $min-1280) {
      @include grid-col(6);
    }
  }

  &__header {
    flex: 0 0 auto;
    position: relative;
    height: 6.6rem;

    z-index: 1;

    @media (min-width: $min-720) {
      height: 10rem + $gutter;
    }

    @media (min-width: $min-1680) {
      background: transparent;
    }

    &.shadow {
      background: linear-gradient(
        to bottom,
        rgba(#111, 1) 20%,
        rgba(#111, 0.9) 70%,
        transparent 100%
      );
    }
  }

  &__footer {
    @include nav-offset-margin;
    padding: $gutter 0 3rem;

    @media (min-width: $min-720) {
      padding: $gutter 0 8rem;
    }

    &Container {
      @include container;

      @media (min-width: $min-600) {
        display: flex;
      }
    }
  }

  &__languages {
    @include container;
    margin-top: 2rem;
  }

  &__figure {
    position: absolute 0;
    padding: 0 (6rem) 0 6rem;
    left: 50%;
    display: none;
    align-items: center;
    justify-content: center;
    z-index: 2;
    pointer-events: none;
    border-left: 1px solid #202020;

    @media (min-width: $min-840) {
      display: flex;
    }

    @media (min-width: $min-960) {
      margin-right: $navigation-button-width;
      left: calc(50% - math.div($navigation-button-width, 2));
    }

    @media (min-width: $min-1080) {
      left: calc(57% - math.div($navigation-button-width, 2));
    }

    @media (min-width: $min-1280) {
      left: calc(50% - math.div($navigation-button-width, 2));
    }

    &Limit {
      width: 60vh;
      max-width: 100%;
    }

    &Constraint {
      margin: auto;
      max-width: 45rem;
    }
  }

  &__image {
    position: relative;
    padding-top: math.div(12, 9) * 100%;
    // background: #000;

    overflow: hidden;
  }

  &__mask,
  &__brandMask {
    position: absolute 0;
    z-index: 20;

    background: #111;
  }

  &__brandMask {
    z-index: 19;
    @include brandColor;
  }

  &__img {
    opacity: 0;

    position: absolute 0;

    background: center no-repeat;
    background-size: cover;
  }

  &__list {
    @include reset-list();
    list-style: none;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: $gutter;
    }
  }

  a {
    @include anchor();
    text-decoration: none;
    color: inherit;
  }
}

.section {
  $indent: 3.5rem;

  display: block;
  padding-left: $indent;

  // &.isActive {
  //   background: red;
  // }

  &__header {
    position: relative;
    margin: 0 0 0 (-$indent);
    color: #fff;

    user-select: none;
  }

  &__limit {
    display: inline-flex;
    align-items: flex-end;
    cursor: pointer;

    transition: color 200ms;

    .isActive &,
    &:hover {
      color: brand();
    }
  }

  &__index {
    display: none;
    width: $indent;

    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: 0.085rem;

    @media (min-width: $min-600) {
      display: inline-block;
    }
  }

  &__title {
    line-height: 1;
    font-size: 3.6rem;
    font-weight: 600;
    letter-spacing: -0.096rem;

    @media (min-width: $min-600) {
      font-size: 2.8rem;
    }
  }

  &__list {
    pointer-events: none;

    .isOpen .isActive & {
      pointer-events: all;
    }

    &:focus-within {
      height: auto !important;

      * {
        opacity: 1 !important;
        transform: 0 !important;
      }
    }

    &Inner {
      display: flex;
      // display: none;
      flex-flow: wrap;
      padding: 3.5rem 0 1.5rem;
    }
  }
}

.item {
  line-height: 1.5;
  font-size: 1.8rem;
  color: #9b9b9b;
  padding: 0.7rem 0;
  display: block;
  width: 100%;

  @media (min-width: $min-600) {
    width: 50%;
    font-size: 1.6rem;
    color: #fff;
  }

  @media (min-width: $min-840) {
    width: 100%;
  }

  @media (min-width: $min-1080) {
    width: 50%;
  }

  &__link {
    &.isActive {
      color: brand();
    }
  }
}

.footerItem {
  display: block;

  margin-right: $gutter;

  line-height: 1.5;
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #9b9b9b;
  padding: 1rem 0;

  @media (min-width: $min-600) {
    color: #fff;
  }
}
